import { debounce } from 'widgets/toolbox/debounce';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Cuttable content implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof CuttableContent} CuttedContent class
 */
export default function (Widget) {
    /**
     * @class CuttableContent
     * @augments Widget
     */
    class CuttableContent extends Widget {
        prefs() {
            return {
                moreClass: 'm-show-more',
                showMore: '',
                showLess: '',
                maxHeight: 0,
                useLess: true,
                ...super.prefs()
            };
        }

        init() {
            this.toggleButton();

            this.opened = false;

            this.ev('resize', debounce(this.toggleButton.bind(this), 200), window);
        }

        onRefresh() {
            this.toggleButton();
        }

        toggleButton() {
            this.has('button', button => {
                this.has('content', content => {
                    if (this.prefs().maxHeight && this.prefs().maxHeight > content.prop('scrollHeight')) {
                        button.hide();
                    } else if (!this.prefs().useLess || !this.opened) {
                        button.show();
                    }
                });
            });
        }

        toggleContent(link) {
            const prefs = this.prefs();

            this.has('content', content => content.toggleClass(prefs.moreClass));

            if (this.prefs().useLess) {
                link.setText(this.opened ? prefs.showMore : prefs.showLess);
            } else if (!this.opened) {
                link.hide();
            }

            this.opened = !this.opened;
        }
    }

    return CuttableContent;
}
